var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "add-new-investor-form"
  }, [_c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "content"
  }, [_c('sygni-container-title', {
    staticClass: "mb-3"
  }, [_vm._v("Add new fund manager")]), _c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "options": _vm.selects.fundType,
      "label": 'FUND TYPE',
      "placeholder": 'Choose fund type',
      "validation": _vm.$v.fundType
    },
    model: {
      value: _vm.fundType,
      callback: function callback($$v) {
        _vm.fundType = $$v;
      },
      expression: "fundType"
    }
  }), _c('sygni-rounded-button', {
    staticClass: "outline disabled secondary",
    attrs: {
      "disabled": !_vm.fundType || _vm.fundType === '',
      "arrow-icon": true
    },
    on: {
      "click": _vm.goNext
    }
  }, [_vm._v("Next step")]), _c('router-link', {
    attrs: {
      "to": {
        name: 'select-fund',
        query: {
          id: _vm.$route.query.id
        }
      }
    }
  }, [_c('div', {
    staticClass: "cancel-button hoverable-a-element"
  }, [_vm._v("Cancel")])])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }